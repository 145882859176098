import * as React from "react"
import { Box, Heading, Paragraph } from "theme-ui"
import { SimpleButton } from "../components/modern/buttons"
import { PaddedContent, PageWrapper } from "../components/modern/layout"

const NotFoundPage = () => {
  return (
    <PageWrapper title="Page not found" pathname="/404.html">
      <PaddedContent>
        <Box
          sx={{
            marginX: `auto`,
            marginY: `10em`,
            flexDirection: `column`,
            maxWidth: `30em`,
          }}
        >
          <Heading
            sx={{
              textTransform: `uppercase`,
              fontSize: `4em`,
              fontWeight: `bold`,
            }}
          >
            Page not found
          </Heading>
          <Paragraph
            sx={{
              marginTop: `.8em`,
              marginBottom: `2em`,
            }}
          >
            Oops! The page you are looking for has been removed or relocated.
          </Paragraph>
          <SimpleButton label="Go back" url={`/`} />
        </Box>
      </PaddedContent>
    </PageWrapper>
  )
}
export default NotFoundPage
